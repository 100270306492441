@layer base {
  .wrapper {
    display: flex;
    gap: var(--spacer-100);
    padding: var(--spacer-150) var(--spacer-200);
    transition: background-color 200ms;
}

.wrapper:hover {
    background-color: var(--color-grey--050);
}

.logo {
    width: 40px;
    height: 40px;
    overflow: hidden;
    border-radius: var(--radius-100);
    background-color: var(--color-grey--100);
}

.nameWrapper {
    margin-bottom: var(--spacer-25);
}

.name {
    font-weight: bold;
}

.tag {
    margin-left: var(--spacer-100);
}
}