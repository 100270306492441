@layer modules, ui, base;
@layer base {
  .Nav_app-Nav__YDPug {
  width: 100%;
  margin: 0;
  padding: 0;
}

.Nav_app-Nav__item__Ns0tT {
  display: inherit;
  align-items: center;
}

.Nav_app-Nav__link__W3ONF {
  display: block;
  position: relative;
  box-sizing: border-box;
  padding: var(--sk-space-16) 0;
  color: var(--themeButtonTextColor, var(--sk-color-black));
  font-size: var(--typo-body-2-font-size);
  font-weight: var(--black);
  line-height: var(--typo-body-2-line-height);
  text-decoration: none;
  white-space: nowrap;
}

.Nav_app-Nav__link__W3ONF:hover {
  color: var(--themeButtonTextColor, var(--color-primary));
}

.Nav_app-Nav__link--active__HuxqT {
  color: var(--themeButtonTextColor, var(--color-primary));
}

.Nav_app-Nav__newTag__ZpufN {
  margin-left: var(--sk-space-8);
}

@media (max-width: 63.999rem) { /* --medium-and-less-only */
  .Nav_app-Nav__link__W3ONF {
    margin: 0 var(--sk-space-24);
    border-bottom: thin solid var(--themeButtonTextColor, var(--sk-color-grey-100));
  }

  .Nav_app-Nav__personasList__rsQGs {
    margin-top: var(--sk-space-16);
    padding: 0;
    list-style: none;
  }

  .Nav_app-Nav__personasItem--active__HJSRA {
    display: none;
  }

  .Nav_app-Nav__personasLink__XjLP_ {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 var(--sk-space-24);
    padding: var(--sk-space-16) 0;
    border-bottom: thin solid var(--sk-color-grey-100);
    color: var(--sk-color-black);
    font-size: var(--typo-body-2-font-size);
    line-height: var(--typo-body-2-line-height);
    text-decoration: none;
  }
}

@media (min-width: 64rem) { /* --large-viewport */
  .Nav_app-Nav__personas__d2Ecs {
    --layout-size: var(--mj-breakpoint);
    display: flex;
    position: relative;
    flex-direction: column;
    width: 100%;
    max-width: var(--layout-size);
    min-height: 40px;
    margin: auto;
  }

  .Nav_app-Nav__personasList__rsQGs {
    display: flex;
    flex-grow: 1;
    gap: 0 var(--sk-space-24);
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .Nav_app-Nav__personasItem__HuBH4 {
    display: flex;
  }

  .Nav_app-Nav__personasLink__XjLP_ {
    position: relative;
    padding: var(--sk-space-8) 0;
    color: var(--sk-color-black);
    font-size: var(--typo-body-3-font-size);
    line-height: var(--typo-body-3-line-height);
    text-decoration: none;
    text-transform: capitalize;
  }

  .Nav_app-Nav__personasLink--active__rGZ4z {
    font-weight: var(--font-weight-bold);
  }

  .Nav_app-Nav__personasLink__XjLP_:not(.Nav_app-Nav__personasLink--active__rGZ4z) {
    color: var(--sk-color-white);
  }

  .Nav_app-Nav__personasLink__XjLP_:hover,
  .Nav_app-Nav__personasLink--active__rGZ4z {
    color: var(--color-primary);
  }

  .Nav_app-Nav__personasLink__XjLP_:hover::after,
  .Nav_app-Nav__personasLink--active__rGZ4z::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    border-bottom: 2px solid var(--color-primary);
  }

  .Nav_app-Nav__personasLinkIcon__7ECJk {
    display: none;
  }

  .Nav_app-Nav__YDPug {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 0;
  }

  .Nav_app-Nav__link__W3ONF {
    display: flex;
    align-items: center;
    height: var(--sk-space-64);
    padding: 0 0.75rem;
    font-size: 0.875rem;
    font-weight: var(--font-weight-regular);
    text-align: center;
  }

  .Nav_app-Nav__link--active__HuxqT {
    font-weight: var(--font-weight-bold);
  }

  .Nav_app-Nav__link__W3ONF:hover::after,
  .Nav_app-Nav__link--active__HuxqT::after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: var(--sk-space-12);
    width: calc(100% - var(--sk-space-24));
    height: 0.125rem;
    background-color: var(--themeButtonTextColor, var(--color-primary));
  }
}

/******************************
  UI Shift
*******************************/
/* stylelint-disable plugin/selector-bem-pattern */
[data-theme="jobteaser-uishift"] .Nav_app-Nav__item--offers__u7Yqo { order: 1; }
[data-theme="jobteaser-uishift"] .Nav_app-Nav__item--companies__QU_5_ { order: 2; }
[data-theme="jobteaser-uishift"] .Nav_app-Nav__item--events__IblND { order: 3; }
[data-theme="jobteaser-uishift"] .Nav_app-Nav__item--community__17gx_ { order: 4; }
[data-theme="jobteaser-uishift"] .Nav_app-Nav__item--jobs__BR2xW { order: 5; }
[data-theme="jobteaser-uishift"] .Nav_app-Nav__item--jobRoles__aFmJi { order: 5; }
[data-theme="jobteaser-uishift"] .Nav_app-Nav__item--advices__D7eCm { order: 6; }
[data-theme="jobteaser-uishift"] .Nav_app-Nav__item--handbook__rAQDn { order: 7; }
[data-theme="jobteaser-uishift"] .Nav_app-Nav__item--ressources__aK2kz { order: 8; }
[data-theme="jobteaser-uishift"] .Nav_app-Nav__item--appointment__J_xa_ { order: 9; }
[data-theme="jobteaser-uishift"] .Nav_app-Nav__item--careerExplorer__q_aL5 { order: 10; }
[data-theme="jobteaser-uishift"] .Nav_app-Nav__item--login__OX5tA { order: 11; }

[data-theme="jobteaser-uishift"] .Nav_app-Nav__link__W3ONF {
  color: var(--sk-color-black);
  font-weight: var(--font-weight-regular);
}

[data-theme="jobteaser-uishift"] .Nav_app-Nav__link--active__HuxqT {
  font-weight: var(--font-weight-semibold);
}

[data-theme="jobteaser-uishift"] .Nav_app-Nav__linkLang__MY7Bx {
  color: var(--sk-color-white);
}

@media (max-width: 63.999rem) { /* --medium-and-less-only */
  [data-theme="jobteaser-uishift"] .Nav_app-Nav__YDPug {
    display: flex;
    flex-direction: column;
  }

  [data-theme="jobteaser-uishift"] .Nav_app-Nav__link__W3ONF {
    width: 100%;
    border-bottom-color: var(--sk-color-grey-100);
  }
}

@media (min-width: 64rem) { /* --large-viewport */
  [data-theme="jobteaser-uishift"] .Nav_app-Nav__item--dashboard__1NSLW {
    display: none;
  }

  [data-theme="jobteaser-uishift"] .Nav_app-Nav__link__W3ONF:hover::after,
  [data-theme="jobteaser-uishift"] .Nav_app-Nav__link--active__HuxqT::after {
    background-color: var(--sk-color-black);
  }
}

}
@layer base {
  .DropdownCommunity_hdr-DropdownCommunity__X_wja {
  display: inline-block;
  position: relative;
  outline: 0;
  cursor: pointer;
}

.DropdownCommunity_hdr-DropdownCommunity--mobile__Qepmd {
  position: static;
}

.DropdownCommunity_hdr-DropdownCommunity--header__KvoZ9 {
  width: 100%;
}

.DropdownCommunity_hdr-DropdownCommunity__content___1isw {
  display: none;
  visibility: hidden;
  position: absolute;
  z-index: var(--z-up-in-the-current-stacking-context); /* TODO: what is the current stacking context? */
  top: 80%;
  right: 0;
  box-sizing: border-box;
  min-width: 270px;
  padding: 0 1.875rem 1.875rem;
  transition: visibility 0.5s;
  opacity: 0;
  pointer-events: auto;
}

.DropdownCommunity_hdr-DropdownCommunity__X_wja:hover .DropdownCommunity_hdr-DropdownCommunity__content___1isw,
.DropdownCommunity_hdr-DropdownCommunity__content___1isw:hover {
  display: block;
  visibility: visible;
  position: static;
  opacity: 1;
  background-color: #ffffff;
  box-shadow: var(--shadow-default);
}

.DropdownCommunity_hdr-DropdownCommunity__X_wja:hover .DropdownCommunity_hdr-DropdownCommunity__content--mobile__dYqg1 {
  position: absolute;
  top: 105%;
  width: 98%;
  margin: 0 1%;
  padding-top: 8px;
  padding-bottom: 8px;
}

.DropdownCommunity_hdr-DropdownCommunity__item__by3SB {
  list-style-type: none;
}

/** Dropdown link content */

.DropdownCommunity_hdr-DropdownCommunity__link__Ejy5j {
  display: flex;
  align-items: center;
  color: var(--themeButtonTextColor);
  text-decoration: none;
}

.DropdownCommunity_hdr-DropdownCommunity__link__Ejy5j:hover {
  background-color: var(--sk-color-grey-025);
  color: var(--themeButtonTextColor);
}

.DropdownCommunity_hdr-DropdownCommunity__link__Ejy5j::after {
  background-color: var(--themeButtonTextColor);
}

.DropdownCommunity_hdr-DropdownCommunity__linkTextWrapper__d37Kl {
  margin: 0;
}

.DropdownCommunity_hdr-DropdownCommunity__linkTitle__PQ54S {
  color: var(--themeMainTextColor);
  font-size: 0.875rem;
  line-height: 1.5rem;
}

.DropdownCommunity_hdr-DropdownCommunity--nav__bQosD {
  display: flex;
  align-items: center;
}

.DropdownCommunity_hdr-DropdownCommunity--nav__bQosD svg {
  padding-left: var(--sk-space-4);
}

@media (max-width: 47.999rem) { /* --small-and-less-only */
  .DropdownCommunity_hdr-DropdownCommunity--nav__bQosD > svg {
    display: none;
  }
}

@media (min-width: 64rem) { /* --large-viewport */
  .DropdownCommunity_hdr-DropdownCommunity--mobile__Qepmd {
    position: relative;
  }

  .DropdownCommunity_hdr-DropdownCommunity--header__KvoZ9 {
    width: inherit;
  }

  .DropdownCommunity_hdr-DropdownCommunity__content___1isw {
    display: block;
    min-width: 345px;
    padding: 0;
    cursor: initial;
  }

  .DropdownCommunity_hdr-DropdownCommunity__content--left__6B9lO {
    right: inherit;
    left: 0;
  }

  .DropdownCommunity_DropdownCommunity__list__OS6Fe {
    padding: 8px;
  }

  .DropdownCommunity_hdr-DropdownCommunity__X_wja:hover .DropdownCommunity_hdr-DropdownCommunity__content___1isw {
    position: absolute;
    box-shadow: 0 10px 20px 0 rgb(0 0 0 / 20%);
  }

  .DropdownCommunity_hdr-DropdownCommunity__X_wja:hover .DropdownCommunity_hdr-DropdownCommunity__content--mobile__dYqg1 {
    position: absolute;
    top: 80%;
    width: inherit;
    margin: 0;
    padding-top: 0;
    padding-bottom: 0;
  }

  .DropdownCommunity_hdr-DropdownCommunity__link__Ejy5j {
    padding: 8px;
  }
}

/******************************
  UI Shift
*******************************/
/* stylelint-disable plugin/selector-bem-pattern */

[data-theme="jobteaser-uishift"] .DropdownCommunity_hdr-DropdownCommunity__content___1isw {
  border-radius: var(--sk-radius-8);
}

[data-theme="jobteaser-uishift"] .DropdownCommunity_hdr-DropdownCommunity__linkTitle__PQ54S {
  color: var(--sk-color-black);
}

}
@layer base {
  .Header_main__Zk9sm {
  --mj-breakpoint: 80rem;
  --font-weight-bold: 700;
  --font-weight-semibold: 600;
  --font-weight-regular: 400;
  --typo-body-2-font-size: 16px;
  --typo-body-2-line-height: 24px;
  --typo-body-3-font-size: 14px;
  --typo-body-3-line-height: 24px;
  --color-primary: #4dc942; /* Legacy Green */
  --shadow-default: 0 4px 8px rgb(118 118 120 / 20%);
  position: relative;
  z-index: var(--z-index-website-menu);
  background-color: var(--themeMainColor, var(--sk-color-white));
  box-shadow: var(--shadow-default);
}

.Header_nav__IP0B3 {
  --layout-size: 100%;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: auto auto 1fr auto;
  max-width: var(--layout-size);
}

.Header_link__lueRy {
  color: var(--color-grey--900);
  text-decoration: none;
}

.Header_logo___onO5 {
  display: flex;
  grid-column-start: 1;
  align-items: center;
  height: var(--sk-space-64);
}

.Header_dropdown__b9_DX {
  display: flex;
  grid-column-start: 2;
}

.Header_topNav__T8_X2 {
  grid-column: 1 / span 2;
  grid-row-start: 2;
}

.Header_primaryNav__lM5sM {
  grid-column: 1 / span 2;
  grid-row-start: 3;
}

.Header_primaryNav__hidden__JAZy2 {
  visibility: hidden;
}

.Header_language__wLGkn {
  grid-column: 1 / span 2;
  grid-row-start: 4;
}

@media (max-width: 63.999rem) {
  /* --medium-and-less-only */

  .Header_main__Zk9sm .Header_nav__IP0B3.nav--open {
    position: fixed;
    z-index: inherit;
    top: 0;
    left: 0;
    width: 100vw;
    width: 100dvw;
    height: 100vh;
    height: 100dvh;
    overflow-y: auto;
    background-color: inherit;
  }

  .Header_main__Zk9sm .Header_nav__IP0B3:not(.nav--open) .Header_topNav__T8_X2,
  .Header_main__Zk9sm .Header_nav__IP0B3:not(.nav--open) .Header_primaryNav__lM5sM,
  .Header_main__Zk9sm .Header_nav__IP0B3:not(.nav--open) .Header_language__wLGkn,
  .Header_main__Zk9sm .Header_nav__IP0B3.nav--open .Header_account__jNuE2 {
    display: none;
  }

  .Header_dropdown__b9_DX {
    flex-direction: row-reverse;
  }

  .Header_primaryNav__lM5sM {
    padding-top: var(--sk-space-16);
    padding-bottom: var(--sk-space-40);
  }

  .Header_topNav__T8_X2 + .Header_primaryNav__lM5sM {
    display: flex;
    align-items: flex-end;
    padding-top: var(--sk-space-48);
  }

  .Header_dropdownButton__Geqm7 {
    display: flex;
    margin-right: var(--sk-space-12);
  }

  .Header_app-Header__dropdownMenu__Lice9 {
    visibility: hidden;
    position: absolute;
    width: 100%;
  }

  .Header_language__wLGkn {
    padding: var(--sk-space-32) var(--sk-space-24);
  }
}

/* Only for devices with small screen height */

@media (max-height: 699px) and (max-width: 1023px) {
  .Header_language__wLGkn {
    padding-top: var(--sk-space-48);
  }
}

@media (min-width: 64rem) {
  /* --large-viewport */
  .Header_main__fixed__saFy6 {
    position: fixed;
    top: 0;
    width: 100%;
  }

  .Header_header--anonymousUser__h2s0j {
    background: linear-gradient(0deg, var(--sk-color-white) 61.54%, var(--sk-color-grey-700) 61.54%);
  }

  .Header_nav__IP0B3 {
    --layout-size: var(--mj-breakpoint);
    box-sizing: border-box;
    grid-template-columns: auto 1fr auto auto;
    grid-template-rows: auto auto;
    margin: auto;
    padding: 0 var(--sk-space-24);
  }

  .Header_logo___onO5 {
    grid-column: 1;
    grid-row-start: 2;
  }

  .Header_app-Header__logo__Ikfct {
    height: var(--sk-space-32);
  }

  .Header_dropdown__b9_DX {
    grid-column: 3;
    grid-row-start: 2;
  }

  .Header_topNav__T8_X2 {
    grid-column: 1 / span 4;
    grid-row-start: 1;
  }

  .Header_primaryNav__lM5sM {
    grid-column: 2;
    grid-row-start: 2;
    text-align: right;
  }

  .Header_language__wLGkn {
    display: flex;
    grid-column: 4;
    grid-row-start: 2;
  }

  .Header_dropdownButton__Geqm7 {
    display: none;
  }
}

/******************************
  UI Shift
*******************************/
/* stylelint-disable plugin/selector-bem-pattern */

[data-theme="jobteaser-uishift"] .Header_main__Zk9sm {
  --color-primary: var(--sk-color-green-500);
  background-color: var(--sk-color-white);
}

[data-theme="jobteaser-uishift"] .Header_logo___onO5 path {
  fill: var(--sk-color-black);
}

@media (min-width: 64rem) {
  /* --large-viewport */
  [data-theme="jobteaser-uishift"] .Header_header--anonymousUser__h2s0j {
    background: linear-gradient(0deg, var(--sk-color-white) 61.54%, var(--sk-color-grey-700) 61.54%);
  }
}

/* we just display the logo on the printed version of the page */

@media print {
  .Header_nav__IP0B3 > :not(.Header_logo___onO5) {
    display: none;
  }

  .Header_logo___onO5 {
    height: var(--sk-space-48);
    margin: auto;
  }
}

}
@layer ui {
  /*  @define CardTitle */
/* stylelint-disable plugin/selector-bem-pattern */
.cardTitle_jds-CardTitle__link__joX8c,
.cardTitle_jds-CardTitle__link__joX8c:visited,
.cardTitle_jds-CardTitle__link__joX8c:hover,
.cardTitle_jds-CardTitle__link__joX8c:active,
.cardTitle_jds-CardTitle__link__joX8c:focus,
[class*="jds-Link"].cardTitle_jds-CardTitle__link__joX8c,
[class*="jds-Link"].cardTitle_jds-CardTitle__link__joX8c:visited,
[class*="jds-Link"].cardTitle_jds-CardTitle__link__joX8c:hover,
[class*="jds-Link"].cardTitle_jds-CardTitle__link__joX8c:active,
[class*="jds-Link"].cardTitle_jds-CardTitle__link__joX8c:focus {
  outline: 0;
  text-decoration: none;
}

.cardTitle_jds-CardTitle__link__joX8c {
  font-weight: var(--bold);
}

.cardTitle_jds-CardTitle__link__joX8c::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: thin solid var(--color-grey--200);
  border-radius: var(--Card-radius);
  box-shadow: none;
}

.cardTitle_jds-CardTitle__link__joX8c:hover::after {
  border-color: var(--color-grey--300);
  box-shadow: var(--hover-shadow);
}

.cardTitle_jds-CardTitle__link__joX8c:active::after {
  border-color: var(--color-grey--400);
}

.cardTitle_jds-CardTitle__link__joX8c:focus::after {
  --CardBorder: 2px;
  border-color: var(--color-grey--200);
  outline: var(--CardBorder) solid var(--color-grey--400);
  outline-offset: var(--CardBorder);
}

@media (min-width: 64rem) {
  .cardTitle_jds-CardTitle--big__tFhdl {
    font-size: var(--heading);
    line-height: var(--headingLineHeight);
  }
}

}
@layer ui {
  /* @define CardImage */

.cardImage_jds-CardImage__I_GO2 {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  overflow: hidden;
  aspect-ratio: 16 / 9;
}

.cardImage_jds-CardImage__I_GO2::before {
  --CardImage-blurSize: 8px;

  content: "";
  position: absolute;
  z-index: 0;
  top: calc(var(--CardImage-blurSize) * -1);
  right: calc(var(--CardImage-blurSize) * -1);
  bottom: calc(var(--CardImage-blurSize) * -1);
  left: calc(var(--CardImage-blurSize) * -1);
  /* "--backgroundImageUrl" variable is defined at the html level (ex: <header style={{"--backgroundImageUrl": `url(url)`}}>) */
  background-image: var(--backgroundImageUrl);
  background-size: contain;
  filter: blur(var(--CardImage-blurSize)) brightness(75%);
}

.cardImage_jds-CardImage__image__jS8zd {
  position: absolute;
  top: 50%;
  width: 100%;
  height: auto;
  transform: translateY(-50%);
}

}
@layer ui {
  /* @define CardTagsListing */

.cardTagListing_jds-CardTagsListing__O6NOO {
  display: inline;
  margin: 0;
  padding: 0;
  list-style: none;
}

.cardTagListing_jds-CardTagsListing__item__WHxmt {
  display: inline-block;
  margin: 0 var(--spacer-100) var(--spacer-50) 0;
}

}
@layer ui {
  /* @define CardTime */

.cardTime_jds-CardTime__5StAm {
  display: block;
  margin-top: var(--spacer-200);
  color: var(--color-grey--700);
}

/* stylelint-disable-next-line plugin/selector-bem-pattern */
[class*="jds-Card--isDark"] .cardTime_jds-CardTime__5StAm {
  color: var(--color-grey--200);
}

@media (min-width: 48rem) {
  .cardTime_jds-CardTime__5StAm:not(.cardTime_jds-CardTime--isPush__yMi7n) {
    flex: 1 auto;
    align-self: flex-end;
    text-align: right;
  }
}

@media (min-width: 64rem) {
  .cardTime_jds-CardTime__5StAm:not(.cardTime_jds-CardTime--isPush__yMi7n) {
    flex: 1 0 auto;
  }
}

}
@layer ui {
  /* @define Listing */

.cardListing_jds-Listing__6rNno {
  display: block;
  margin: 0;
  padding-left: 0;
  list-style: none;
}

.cardListing_jds-Listing__Item__nw0fv {
  display: block;
}

.cardListing_jds-Listing__6rNno:not(.cardListing_jds-Listing--removeSpacing__fD5lO) .cardListing_jds-Listing__Item__nw0fv:not(:first-child) {
  margin-top: var(--spacer-200);
}

@media (min-width: 48rem) {
  .cardListing_jds-Listing__6rNno:not(.cardListing_jds-Listing--isPush__XYDKK) {
    display: block;
  }

  .cardListing_jds-Listing__6rNno:not(.cardListing_jds-Listing--isPush__XYDKK) .cardListing_jds-Listing__Item__nw0fv {
    display: inline;
    margin-bottom: 0;
  }

  .cardListing_jds-Listing__6rNno:not(.cardListing_jds-Listing--isPush__XYDKK) .cardListing_jds-Listing__Item__nw0fv > * {
    vertical-align: middle;
  }

  /* stylelint-disable-next-line plugin/selector-bem-pattern */
  .cardListing_jds-Listing__6rNno:not(.cardListing_jds-Listing--isPush__XYDKK) .cardListing_jds-Listing__Item__nw0fv:not(:last-child)::after {
    content: "";
    display: inline-block;
    width: 1px;
    height: 12px;
    margin: 0 var(--spacer-100);
    background: var(--color-grey--500);
    vertical-align: middle;
  }
}

}
@layer ui {
  /* @define CardSpaceBetween */

.cardSpacebetween_jds-CardSpaceBetween__6e4V3 {
  align-items: flex-start;
}

.cardSpacebetween_jds-CardSpaceBetween__6e4V3:not(.cardSpacebetween_jds-CardSpaceBetween--removeSpacing__OQDQz) {
  margin-top: var(--spacer-200);
}

/* stylelint-disable-next-line plugin/selector-bem-pattern */
.cardSpacebetween_jds-CardSpaceBetween__6e4V3 > *:not(:first-child) {
  margin-top: var(--spacer-200);
}

.cardSpacebetween_jds-CardSpaceBetween--hasDivider__mapyk {
  padding-top: var(--spacer-200);
  border-top: var(--border);
}

@media (min-width: 48rem) {
  .cardSpacebetween_jds-CardSpaceBetween__6e4V3:not(.cardSpacebetween_jds-CardSpaceBetween--isPush__TJ16i) {
    display: flex;
    justify-content: space-between;
    gap: var(--spacer-200);
  }

  /* stylelint-disable plugin/selector-bem-pattern */
  .cardSpacebetween_jds-CardSpaceBetween__6e4V3:not(.cardSpacebetween_jds-CardSpaceBetween--isPush__TJ16i) > *,
  .cardSpacebetween_jds-CardSpaceBetween__6e4V3:not(.cardSpacebetween_jds-CardSpaceBetween--isPush__TJ16i) > *:not(:first-child) {
    margin-top: 0;
  }
  /* stylelint-enable plugin/selector-bem-pattern */
}

}
@layer ui {
  /* @define CardFeedback */

.cardFeedback_jds-CardFeedback__Lr8jw {
  margin-top: var(--spacer-200);
  color: var(--mainColor);
  font-weight: var(--bold);
}

.cardFeedback_jds-CardFeedback__Text__aoaF2 {
  margin-right: var(--spacer-100);
  color: var(--color-black);
  vertical-align: middle;
}

}
@layer base {
  .Network_container__yLIzl a[class*="jds-Pagination__activeButton"] {
  background: var(--color-black) !important;
  color: var(--color-white) !important;
}

.Network_filters__DFLfT {
  position: relative;
  z-index: var(--z-above-ui-kit-cards); /* to override some z-index inside Cards, below the FilterBar */
}

.Network_grid__TrWY3 {
  display: grid;
  grid-auto-rows: minmax(100px, auto);
  gap: var(--spacer-200);
  grid-template-columns: repeat(1, 1fr);
  margin-top: var(--spacer-400);
}

.Network_image__ch_cp {
  border-radius: var(--radius-200);
  -o-object-fit: contain;
     object-fit: contain;
}

@media (min-width: 64rem) {
  .Network_grid__TrWY3 {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 90rem) {
  .Network_grid__TrWY3 {
    grid-template-columns: repeat(4, 1fr);
  }
}

.Network_grid__withError__4IZ8a {
  grid-template-columns: none;
}

}
@layer base {
  .ColGroup_featuresCol__ND0wD {
  width: 23.18%;
}

.ColGroup_packCol--limited__yfGel {
  width: 15.54%;
}

.ColGroup_packCol--light__74tlN,
.ColGroup_packCol--classic__sU67X {
  width: 20.44%;
}

.ColGroup_packCol--performance__qWTab {
  max-width: 20.40%;
}

}
@layer base {
  .IconPackState_main__EEmY6 {
  --jt-IconPackState-size: 1.75rem;

  display: inline-flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  width: var(--jt-IconPackState-size);
  height: var(--jt-IconPackState-size);
  padding: 0.4rem;
  border-radius: 100%;
  background-color: var(--sk-color-black);
  color: var(--sk-color-white);
  font-size: 1rem;
}

.IconPackState_main--included__cHWdw {
  background-color: var(--sk-color-green-550);
}

.IconPackState_main--notIncluded__DtOrF {
  background-color: var(--sk-color-grey-200);
}

}
@layer base {
  .FeaturePackCell_featurePackCell__lonIj {
  border: none;
  border-bottom: 1px solid var(--jt-subscriptionTable-border-color);
  background-color: var(--sk-color-white);
}

.FeaturePackCell_text__XivnQ {
  display: flex;
  flex-direction: column;
}

.FeaturePackCell_bold__1492d {
  font-weight: var(--sk-typography-body-1-bold-font-weight);
}

@media (min-width: 64rem) {
  .FeaturePackCell_featurePackCell__lonIj {
    border: none;
  }
}

}
@layer base {
  .RecruitAdInsert_main__YNrG1 {
  --backgroundImage: unset;

  position: relative;
  margin-bottom: var(--sk-space-80);
  padding: var(--sk-space-32) var(--sk-space-48);
  overflow: hidden;
  border-radius: var(--sk-radius-16);
  background-color: var(--sk-color-purple-400);
  background-image: var(--backgroundImage);
  background-repeat: no-repeat;
  background-position: -200px -200px;
  color: var(--sk-color-white);
  text-shadow: var(--sk-color-purple-500) 0 0 0.625rem;
}

.RecruitAdInsert_content__Fy34I {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: var(--sk-space-32);
}

.RecruitAdInsert_cta__IG_mz {
  flex-shrink: 0;
  text-shadow: none;
}

.RecruitAdInsert_text__HQl8G {
  display: flex;
  flex-direction: column;
  gap: var(--sk-space-16);
}

@media (min-width: 48rem) {
  .RecruitAdInsert_main__YNrG1 {
    padding: var(--sk-space-56) var(--sk-space-72);
  }

  .RecruitAdInsert_content__Fy34I {
    flex-direction: row;
    gap: var(--sk-space-56);
  }
}

}
@layer base {
  .ThPackHeading_main__w6a7Z {
  padding: 0;
  vertical-align: bottom;
}

.ThPackHeading_thWrapper__9Oz6s {
  box-sizing: border-box;
  width: 100%;
  padding: var(--sk-space-32) var(--sk-space-24);
  border-bottom: solid var(--jt-subscriptionTable-border-color) 1px;
  border-top-left-radius: var(--jt-subscriptionTable-border-radius);
  border-top-right-radius: var(--jt-subscriptionTable-border-radius);
  background-color: var(--sk-color-white);
  vertical-align: bottom;
}

.ThPackHeading_main__w6a7Z:nth-child(2) .ThPackHeading_thWrapper__9Oz6s {
  border-bottom: none;
}

.ThPackHeading_title__OQUuy {
  margin-bottom: var(--sk-space-8);
}

.ThPackHeading_thBadge__K1LNb {
  margin: var(--sk-space-8) 0;
}

@media (min-width: 64rem) {
  .ThPackHeading_thWrapper__9Oz6s,
  .ThPackHeading_main__w6a7Z:nth-child(2) .ThPackHeading_thWrapper__9Oz6s {
    border: solid var(--jt-subscriptionTable-border-color) 1px;
  }
}

}
@layer base {
  .LanguageDropdown_triggerButton__ot69F {
  border-color: transparent;
  outline: 1px solid var(--color-grey--200);
  background: var(--color-white);
  color: var(--color-grey--600);
}

}
@layer base {
  .ActionBar_column__tAwEt {
  margin-top: 0;
  margin-bottom: 0;
}

.ActionBar_actionsContainer__33tF1 {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin: var(--spacer-300) 0;
  gap: var(--spacer-200);
}

.ActionBar_buttonsContainer__A2jG_ {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: var(--spacer-200);
}

}
@layer ui {
  /* @define FormTemplate */

.Aside_jds-FormTemplate__aside__PtZzw {
  display: none;
}

.Aside_jds-FormTemplate__asideSticky__KjLEQ {
  position: sticky;
  top: var(--spacer-600);
}

/* stylelint-disable plugin/selector-bem-pattern */
.Aside_jds-FormTemplate__asideSticky__KjLEQ > *:first-child {
  margin-top: 0;
}
/* stylelint-enable plugin/selector-bem-pattern */

@media (min-width: 48rem) {
  .Aside_jds-FormTemplate__aside__PtZzw {
    display: flex;
    flex-direction: column;
  }
}

}
@layer ui {
  .InternalAnchorsItem_jds-InternalAnchorsItem__6d3_k {
  position: relative;
  width: 100%;
  list-style-type: none;
  font-size: var(--smallBody);
  font-weight: var(--semiBold);
  line-height: var(--smallBodyLineHeight);
}

.InternalAnchorsItem_jds-InternalAnchorsItem__6d3_k::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0.125rem; /* 2px */
  height: 100%;
}

.InternalAnchorsItem_jds-InternalAnchorsItem__6d3_k:focus-within:not(.InternalAnchorsItem_jds-InternalAnchorsItem--active__r4e28)::before,
.InternalAnchorsItem_jds-InternalAnchorsItem__6d3_k:hover::before {
  background-color: var(--color-grey--500);
}

.InternalAnchorsItem_jds-InternalAnchorsItem--active__r4e28:hover::before,
.InternalAnchorsItem_jds-InternalAnchorsItem--active__r4e28::before {
  background-color: var(--color-black);
}

.InternalAnchorsItem_jds-InternalAnchorsItem__anchor__MxPeD {
  display: block;
  padding: var(--spacer-150) var(--spacer-100) var(--spacer-150) var(--spacer-200);
  color: var(--color-grey--600);
  text-decoration: none;
}

.InternalAnchorsItem_jds-InternalAnchorsItem__anchor__MxPeD:focus-visible {
  outline: none;
}

.InternalAnchorsItem_jds-InternalAnchorsItem__anchor__MxPeD:hover,
.InternalAnchorsItem_jds-InternalAnchorsItem__anchor__MxPeD:focus-visible {
  color: var(--color-grey--600);
}

.InternalAnchorsItem_jds-InternalAnchorsItem--active__r4e28 .InternalAnchorsItem_jds-InternalAnchorsItem__anchor__MxPeD {
  color: var(--color-black);
}

}
@layer ui {
  .InternalAnchors_jds-InternalAnchors__VuK70 {
  position: relative;
  height: -moz-fit-content;
  height: fit-content;
}

.InternalAnchors_jds-InternalAnchors__VuK70::before {
  content: "";
  position: absolute;
  left: 0;
  width: 0.125rem; /* 2px */
  height: 100%;
  background-color: var(--color-grey--100);
}

.InternalAnchors_jds-InternalAnchors__list__Dx6Ic {
  margin: 0;
  padding: 0;
}

}
@layer ui {
  /* @define FormTemplate */

/* stylelint-disable plugin/selector-bem-pattern */
.Body_jds-FormTemplate__body__ll3td > *:first-child {
  margin-top: 0;
}
/* stylelint-enable plugin/selector-bem-pattern */

}
@layer ui {
  /* @define FormTemplate */

.FormTemplate_jds-FormTemplate__plEnK {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.FormTemplate_jds-FormTemplate__body__3ROVp {
  flex-grow: 1;
  padding-top: var(--spacer-600);
  padding-bottom: var(--spacer-600);
  background-color: var(--color-grey--025);
}

.FormTemplate_jds-FormTemplate__body--without-aside__ibkpX {
  display: flex;
  justify-content: center;
}

/* stylelint-disable plugin/selector-bem-pattern */
.FormTemplate_jds-FormTemplate__body--without-aside__ibkpX > * {
  max-width: 800px;
}

}
@layer ui {
  /* @define FormTemplate */

.Footer_jds-FormTemplate__footer__o9mUC {
  --FormTemplate-Footer-z-index: 10;

  position: sticky;
  z-index: var(--FormTemplate-Footer-z-index);
  bottom: 0;
  border-top: solid 1px var(--color-grey--100);
  background-color: var(--color-white);
}

.Footer_jds-FormTemplate__footerWrapper__4MkIa {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: var(--spacer-200);
  padding-bottom: var(--spacer-200);
  gap: var(--spacer-200);
}

}
@layer ui {
  .FooterSeparator_jds-FormTemplate__footerSeparator__1RZYU {
  box-sizing: border-box;
  flex-shrink: 0;
  width: 1px;
  height: 1.5rem;
  margin: var(--spacer-150) var(--spacer-100);
  border: 0;
  background-color: var(--color-grey--100);
}

}
@layer ui {
  /* @define FormTemplate */

.Header_jds-FormTemplate__header___x2Tm {
  display: flex;
  box-sizing: border-box;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  min-height: 4rem; /* 64px */
  padding-top: var(--spacer-200);
  padding-bottom: var(--spacer-200);
  gap: var(--spacer-100);
  overflow-x: auto;
}

@media (min-width: 48rem) {
  .Header_jds-FormTemplate__header___x2Tm {
    flex-wrap: nowrap;
  }
}

}
@layer ui {
  /* @define ResponsiveButtons */

.ResponsiveButtons_jds-ResponsiveButtons__hideMobile__ML96T {
  display: none;
}

.ResponsiveButtons_jds-ResponsiveButtons__fluidMobileButton__hNrPf {
  width: 100%;
}

@media (min-width: 48rem) {
  /* stylelint-disable-next-line plugin/selector-bem-pattern */ /* needed to ensure CSS priority */
  [class*="jds-Dropdown"].ResponsiveButtons_jds-ResponsiveButtons__mobileOnly__MWTvd {
    display: none;
  }

  .ResponsiveButtons_jds-ResponsiveButtons__hideMobile__ML96T {
    display: block;
  }

  .ResponsiveButtons_jds-ResponsiveButtons__fluidMobileButton__hNrPf {
    width: -moz-max-content;
    width: max-content;
  }
}

}
@layer ui {
  .arrow_jds-arrow__GdEXX {
  display: none;
  z-index: 1 !important;
}

@media (min-width: 48rem) {
  .arrow_jds-arrow__GdEXX {
    /* Get the same display as the button with only icon */
    display: inline-flex;
    position: absolute;
    align-self: center;
    transform: translate(-50%, 0);
    border: none;
  }

  .arrow_jds-arrow__GdEXX:focus {
    outline: none !important;
  }

  .arrow_jds-arrow--hidden__tlIYW {
    display: none;
  }
}

}
@layer ui {
  .content_jds-content__separator__bOxyb {
  margin: 0 var(--spacer-100);
  background-color: var(--color-grey--100);
}

@media (min-width: 48rem) {
  .content_jds-content__wrapper__9e0A_ {
    position: relative;
    overflow-y: visible;
  }

  .content_jds-content__separator__bOxyb {
    margin: 0 var(--spacer-150);
  }
}

}
@layer base {
  .Title_title__AcGZQ {
  margin: 0 0 var(--spacer-400);
}

@media (min-width: 48rem) {
  .Title_title__AcGZQ {
    margin-bottom: var(--spacer-700);
    font-size: var(--heading);
    line-height: var(--headingLineHeight);
  }

  .Title_title--small__tCfUd {
    margin-bottom: var(--spacer-600);
    font-size: var(--headingSmall);
    line-height: var(--headingSmallLineHeight);
  }
}

}
@layer ui {
  /**  @define FilterBar **/

.filterBar_jds-FilterBar___Fpze {
  display: flex;
  flex-direction: column;
  box-shadow: var(--default-shadow);
}

.filterBar_jds-FilterBar__element__dSN3u {
  flex: 1;
  min-width: 0;
  margin: 0;
  margin-right: -1px;
  margin-bottom: -1px;
}

.filterBar_jds-FilterBar__element__dSN3u:hover,
.filterBar_jds-FilterBar__element__dSN3u:focus-within {
  z-index: 1;
}

.filterBar_jds-FilterBar__element__dSN3u:not(:first-child, :last-child) .jds-Select__control {
  border-radius: 0;
}

.filterBar_jds-FilterBar__element__dSN3u:first-child .jds-Select__control,
.filterBar_jds-FilterBar__element__dSN3u:first-child > div > input {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.filterBar_jds-FilterBar__element__dSN3u:last-child .jds-Select__control {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

@media (min-width: 48rem) {
  .filterBar_jds-FilterBar___Fpze {
    flex-direction: row;
  }

  .filterBar_jds-FilterBar__element__dSN3u:first-child .jds-Select__control,
  .filterBar_jds-FilterBar__element__dSN3u:first-child > div > input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: var(--radius-200);
  }

  .filterBar_jds-FilterBar__element__dSN3u:last-child .jds-Select__control {
    border-top-left-radius: 0;
    border-top-right-radius: var(--radius-200);
    border-bottom-left-radius: 0;
  }
}

}
@layer base {
  /**
 * FilterBar component is used to wrap Filters and Select. As it override some styles on children, It suffers same issue
 * as Select (src/modules/uiKitOverrides/SelectFilter/Select.module.css), and need some !important in nextjs context.
 */

.FilterBar_filterBar__SW2X9 [class*="jds-FilterBar__element"] {
  margin: 0 0 -1px;
}

.FilterBar_filterBar__SW2X9 [class*="jds-Select__control"] {
  min-height: 48px !important;
}

@media (min-width: 48rem) {
  .FilterBar_filterBar__SW2X9 [class*="jds-FilterBar__element"] {
    margin: 0 -1px -1px 0;
  }

  .FilterBar_filterBar__SW2X9 [class*="jds-FilterBar__element"]:first-child [class*="jds-Select__control"],
  .FilterBar_filterBar__SW2X9 [class*="jds-FilterBar__element"]:first-child [class*="jds-Input"] {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .FilterBar_filterBar__SW2X9 [class*="jds-FilterBar__element"]:last-child [class*="jds-Select__control"],
  .FilterBar_filterBar__SW2X9 [class*="jds-FilterBar__element"]:last-child [class*="jds-Input"] {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .FilterBar_filterBar__SW2X9 [class*="jds-FilterBar__element"]:not(:first-child, :last-child) [class*="jds-Select__control"],
  .FilterBar_filterBar__SW2X9 [class*="jds-FilterBar__element"]:not(:first-child, :last-child) [class*="jds-Input"] {
    border-radius: 0 !important;
  }
}

}
@layer ui {
  /** @define Pagination */

.pagination_jds-Pagination__5KFrR {
  display: flex;
  justify-content: center;
  padding: 0;
  list-style-type: none;
}

.pagination_jds-Pagination__wrapper__wxN4Q {
  margin: var(--spacer-400) 0 var(--spacer-800) 0;
}

.pagination_jds-Pagination__item__55Rn3 {
  display: flex;
  align-items: center;
  margin-right: var(--spacer-200);
  text-align: center;
}

.pagination_jds-Pagination__item__55Rn3:last-child {
  margin-right: 0;
}

.pagination_jds-Pagination__5KFrR .pagination_jds-Pagination__button__GgDAw {
  background-color: transparent !important;
  color: var(--color-black) !important;
}

.pagination_jds-Pagination__5KFrR .pagination_jds-Pagination__button__GgDAw:hover {
  border-color: transparent;
  background-color: var(--color-grey--100) !important;
}

.pagination_jds-Pagination__5KFrR .pagination_jds-Pagination__activeButton__djART,
.pagination_jds-Pagination__5KFrR .pagination_jds-Pagination__button__GgDAw {
  min-width: 40px;
  border-color: transparent !important;
}

.pagination_jds-Pagination__5KFrR .pagination_jds-Pagination__activeButton__djART {
  color: var(--themeButtonTextColor, var(--color-black)) !important;
}

.pagination_jds-Pagination__5KFrR .pagination_jds-Pagination__button--disabled__e4_45 {
  color: var(--color-grey--200) !important;
}

}
@layer ui {
  /** @define Paper */

.paper_jds-Paper__W_bcw {
  padding: var(--spacer-300);
  background-color: var(--color-white);
  box-shadow: var(--default-shadow);
}

.paper_jds-Paper--isDark__BtGLj {
  background-color: var(--color-black);
  color: var(--color-white);
}

.paper_jds-Paper--noSpacing__5fBkQ {
  padding: 0;
}

.paper_jds-Paper--noShadow__6sUru {
  box-shadow: none;
}

.paper_jds-Paper--noBackground__9kbgQ {
  background-color: transparent;
}

.paper_jds-Paper--radius__qzXAo {
  border-radius: var(--radius-200);
}

.paper_jds-Paper--radiusRounded__GhgtS {
  border-radius: var(--radius-900);
}

.paper_jds-Paper--isFullWidth__pe_8P {
  padding-right: 0;
  padding-left: 0;
}

.paper_jds-Paper--hasBorder__DxYyT {
  border: var(--border);
}

@media (min-width: 48rem) {
  .paper_jds-Paper__W_bcw:not(.paper_jds-Paper--smallSpacing__GJYWV, .paper_jds-Paper--isFullWidth__pe_8P, .paper_jds-Paper--noSpacing__5fBkQ) {
    padding: var(--spacer-400) var(--spacer-600);
  }
}

}
@layer ui {
  /* @define FormTemplate */

.Section_jds-FormTemplate__section__fTP0Z {
  margin-top: var(--spacer-300);
}

.Section_jds-FormTemplate__section--withTitle__JaQ04 {
  margin-top: var(--spacer-600);
}

.Section_jds-FormTemplate__section--withTitle__JaQ04:first-of-type {
  margin-top: 0;
}

.Section_jds-FormTemplate__paperSection___1G5q {
  /* Override default Paper component padding. */
  padding: var(--spacer-400) !important;
}

/* stylelint-disable plugin/selector-bem-pattern */
.Section_jds-FormTemplate__paperSection___1G5q > *:last-child {
  margin-bottom: 0;
}

/* stylelint-enable plugin/selector-bem-pattern */

}
@layer base {
  .RadioWithOptionalTooltip_main__Label--disabled__caDCx {
  color: var(--color-grey--500);
}

.RadioWithOptionalTooltip_main__Label__nI_Pe {
  font-size: var(--subheadSmall);
  font-weight: var(--extraBold);
  line-height: var(--subheadSmallLineHeight);
}

}
@layer base {
  .NewLanguage_container__c_93_ {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: var(--spacer-300);
  padding: var(--spacer-500);
  border: solid 1px var(--color-grey--100);
  background-color: var(--color-white);
}

.NewLanguage_buttonsContainer__oW0BS {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: var(--spacer-200);
}

@media (min-width: 48rem) {
  .NewLanguage_container__c_93_ {
    padding: var(--spacer-1000);
  }

  .NewLanguage_heading__agdLl {
    font-size: var(--headingSmall);
    line-height: var(--headingSmallLineHeight);
  }
}

}
@layer ui {
  .header_jds-header--hidden__e3698 {
  opacity: 0;
}

}
@layer ui {
  .item_jds-item--hidden__QPhMf {
  opacity: 0;
}

}
@layer ui {
  /** @define Status */

.status_jds-Status__I8m97 {
  display: inline-flex;
  align-items: center;
}

.status_jds-Status__I8m97::before {
  content: "";
  display: inline-block;
  flex-shrink: 0;
  width: var(--icon-size-tiny);
  height: var(--icon-size-tiny);
  margin-right: var(--spacer-100);
  border-radius: var(--radius-900);
  vertical-align: middle;
}

.status_jds-Status--active__nZ2DW::before {
  background-color: var(--color-success--500);
}

.status_jds-Status--inactive__yIA4M::before {
  background-color: var(--color-error--500);
}

.status_jds-Status--warning__e_U_q::before {
  background-color: var(--color-warning--500);
}

.status_jds-Status--neutral__wkb7R::before {
  background-color: var(--color-grey--200);
}

}
@layer base {
  .PagesStatus_carousel__ZQbjL {
  box-sizing: border-box;
  width: -moz-fit-content;
  width: fit-content;
  max-width: 100%;
  border: var(--border);
  border-radius: var(--radius-100);
}

.PagesStatus_item__WoPIe {
  position: relative;
  width: -moz-max-content;
  width: max-content;
  padding: var(--spacer-200) var(--spacer-300);
  overflow: hidden;
  border: none;
  background: transparent;
  font-family: var(--fontFamily);
  text-align: inherit;
  cursor: pointer;
}

/* setting borders on the first and last items prevents the hover pseudo-element to overflow the card */
/* we can't set an overflow: hidden on the Carousel because it would hide the Carousel arrows */

.PagesStatus_item__first__XG5X3 {
  border-radius: var(--radius-100) 0 0 var(--radius-100);
}

.PagesStatus_item__last__IQnUC {
  border-radius: 0 var(--radius-100) var(--radius-100) 0;
}

.PagesStatus_item__WoPIe:hover::after,
.PagesStatus_item__active__4ThSu::after {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 4px;
}

.PagesStatus_item__WoPIe:hover::after {
  background: var(--color-grey--200);
}

.PagesStatus_item__active__4ThSu::after,
.PagesStatus_item__active__4ThSu:hover::after {
  background: var(--color-black);
}

.PagesStatus_separator__nWorZ {
  flex-shrink: 0;
  width: 1px;
  margin: var(--spacer-200) 0;
  background-color: var(--color-grey--100);
}

.PagesStatus_date__d_EsK {
  color: var(--color-grey--600);
}

}
@layer ui {
  /* @define Note */

.Note_jds-Note__SyY4K {
  display: flex;
  align-items: center;
  padding: var(--spacer-200) var(--spacer-200) var(--spacer-200) var(--spacer-300);
  gap: var(--spacer-200);
  border-radius: var(--radius-200);
}

.Note_jds-Note--loading__OPTYk {
  background-color: var(--color-grey--025) !important;
}

.Note_jds-Note--primary__tnFZB {
  background-color: var(--color-purple--100);
}

.Note_jds-Note--neutral__IOVI2 {
  background-color: var(--color-grey--050);
}

.Note_jds-Note--success__WSt9G {
  background-color: var(--color-success--100);
}

.Note_jds-Note--warning__hPqbt {
  background-color: var(--color-warning--100);
}

.Note_jds-Note--error__TXQcq {
  background-color: var(--color-error--100);
}

.Note_jds-Note__icon--success__v8HMq {
  color: var(--color-black);
}

.Note_jds-Note__icon--warning__8FwVO {
  color: var(--color-warning--500);
}

.Note_jds-Note__icon--error__Gx5Lw {
  color: var(--color-error--500);
}

.Note_jds-Note__body__TbCQO {
  flex: 1;
}

.Note_jds-Note__bodyTitle__PCf77 {
  font-weight: var(--semiBold);
}

.Note_jds-Note__closeButton__vLWj5 {
  align-self: flex-start;
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
}

}
@layer ui {
  /** @define ReadMore */
.Readmore_jds-ReadMore__truncatedText__TrnCV {
  /* https://css-tricks.com/almanac/properties/l/line-clamp/ */
  /* stylelint-disable-next-line value-no-vendor-prefix */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: var(--lineNumber);
  line-clamp: var(--lineNumber);
  overflow: hidden;
  text-overflow: ellipsis;
}

.Readmore_jds-ReadMore__truncatedText--max-height__Wujlz {
  display: block;
  max-height: calc(var(--lineNumber) * 1.8em);
}

.Readmore_jds-ReadMore__truncatedText--expanded__kOY4x {
  max-height: none;
}

.Readmore_jds-ReadMore__link__ETWRT {
  display: inline-block;
  margin-top: var(--spacer-100);
  font-weight: var(--bold);
  cursor: pointer;
}

}
@layer base {
  .PersonalIdentifiableInformationBlock_title__KBp2r {
  margin-top: var(--sk-space-24);
}

.PersonalIdentifiableInformationBlock_content__Ld2nn {
  color: var(--sk-color-grey-600);
  font-size: var(--sk-typography-body-2-regular-font-size);
  line-height: var(--sk-typography-body-2-regular-line-height);
}

}
@layer base {
  .ProfileBlock_title__n_qOJ {
  margin: var(--sk-space-8) 0;
}

.ProfileBlock_courseList__DkNO5 {
  margin: var(--sk-space-16) 0 0;
  padding: 0;
  list-style-type: none;
  color: var(--sk-color-grey-700);
}

.ProfileBlock_schoolName__Uv2Fk + .ProfileBlock_presentation__HDMM8 {
  margin-top: var(--sk-space-16);
}

.ProfileBlock_courseList__DkNO5 + .ProfileBlock_presentation__HDMM8 {
  margin-top: var(--sk-space-4);
}

}
@layer base {
  .SubscriptionModal_section__V86_f {
  position: relative;
  margin-top: var(--sk-space-8);
  padding: var(--sk-space-24);
  border: 1px solid var(--sk-color-grey-100);
  border-radius: var(--sk-radius-4);
}

.SubscriptionModal_editButton__q9V3E {
  margin: 0 0 var(--sk-space-8) var(--sk-space-8);
  float: right;
}

.SubscriptionModal_instructionsContent__cCGpZ {
  white-space: pre-wrap;
}

.SubscriptionModal_instructionNote___J1pk {
  margin-bottom: var(--sk-space-24);
}

.SubscriptionModal_manualNote__5mLJn {
  margin-top: var(--sk-space-24);
}

/* necessary for the skeleton to be exactly the desired height */
/* https://github.com/dvtng/react-loading-skeleton/issues/23#issuecomment-939231878 */
.SubscriptionModal_profileBlockPlaceholder__3yt_G {
  display: block;
  line-height: 1;
}

}
@layer ui {
  /** @define Input */

.input_jds-Input__x_yHZ {
  --Input-iconSize: 1.25rem;

  display: flex;
  box-sizing: border-box;
  align-items: center;
  width: 100%;
  min-height: var(--field-height);
  padding: 0 var(--spacer-200);
  border: thin solid var(--color-grey--200);
  border-radius: var(--radius-200);
  color: var(--color-black);
  font-family: inherit;
  font-size: var(--normalBody);
  font-weight: var(--normal);
  line-height: var(--normalBodyLineHeight);
}

.input_jds-Input--rightIcon__Hcje3 {
  padding-right: calc(var(--Input-iconSize) + var(--spacer-200) + var(--spacer-150));
}

.input_jds-Input--leftIcon__BsHKd {
  padding-left: calc(var(--Input-iconSize) + var(--spacer-200) + var(--spacer-150));
}

.input_jds-Input__x_yHZ:disabled {
  border-color: var(--color-grey--200);
  background-color: var(--color-grey--025);
  color: var(--color-grey--200);
}

.input_jds-Input__x_yHZ::-moz-placeholder {
  opacity: 1;
  color: var(--color-grey--500);
}

.input_jds-Input__x_yHZ::placeholder {
  opacity: 1;
  color: var(--color-grey--500);
}

.input_jds-Input__x_yHZ:disabled::-moz-placeholder {
  color: var(--color-grey--200);
}

.input_jds-Input__x_yHZ:disabled::placeholder {
  color: var(--color-grey--200);
}

.input_jds-Input__x_yHZ:not(:disabled):hover,
.input_jds-Input__x_yHZ:not(:disabled):focus {
  outline: 2px solid var(--color-grey--500);
  outline-offset: -1px;
}

.input_jds-Input--hasError__L_Tsh {
  border-color: var(--color-error--500);
}

.input_jds-Input--hasError__L_Tsh:not(:disabled):hover,
.input_jds-Input--hasError__L_Tsh:not(:disabled):focus {
  outline-color: var(--color-error--500);
}

}
@layer base {
  .RadioRemote_option__LIM77 {
  display: block;
}

.RadioRemote_option__LIM77:not(:last-child) {
  margin-bottom: var(--spacer-200);
}

.RadioRemote_radioGroup__jbjqF {
  margin-bottom: var(--spacer-150);
}

.RadioRemote_field__f9oPf {
  margin-bottom: var(--spacer-400);
}

.RadioRemote_title__uHNw_ {
  margin-bottom: var(--spacer-200);
}

}
@layer base {
  .RichTextDescription_descriptionLabel__pn8Ew {
  display: block;
}

.RichTextDescription_RichTextEditor--loading__wNYgB {
  width: 100%;
  height: 343px;
}

.RichTextDescription_wrapper__VlG_8 .ql-toolbar {
  position: sticky;
  z-index: var(--z-up-in-the-current-stacking-context); /* TODO: what is the current stacking context? */
  top: 0;
  background-color: var(--color-white);
}

.RichTextDescription_wrapper__VlG_8 .ql-editor {
  --RichTextEditor-min-height: 300px;
  min-height: var(--RichTextEditor-min-height);
  overflow-y: scroll;
  font-size: var(--normalBody);
  font-weight: var(--normal);
  line-height: var(--normalBodyLineHeight);
  resize: vertical;
}

.RichTextDescription_wrapper__VlG_8 .ql-size-large,
.RichTextDescription_wrapper__VlG_8 .ql-header .ql-picker-item[data-value="2"]::before,
.RichTextDescription_wrapper__VlG_8 .ql-editor h2 {
  margin: 0 0 var(--spacer-100);
  color: var(--color-black);
  font-size: var(--subheadSmall);
  font-weight: var(--black);
  line-height: var(--subheadSmallLineHeight);
}

.RichTextDescription_wrapper__VlG_8 .ql-picker.ql-header .ql-picker-label[data-value="2"]::before,
.RichTextDescription_wrapper__VlG_8 .ql-header .ql-picker-item[data-value="2"]::before {
  content: "Large";
}

.RichTextDescription_tipsWrapper___KBV0 {
  margin: var(--spacer-50) 0 0 var(--spacer-200);
  padding: 0;
}

.RichTextDescription_tipsItemBold__atXcs {
  font-weight: var(--bold);
}

.RichTextDescription_tips__B8b71 {
  margin-bottom: var(--spacer-400);
}

.RichTextDescription_field__LLSmx {
  margin-bottom: var(--spacer-25);
}

}
@layer base {
  .SelectCompany_wrapper__jh1zW {
    display: flex;
    gap: var(--spacer-100);
    padding: var(--spacer-150) var(--spacer-200);
    transition: background-color 200ms;
}

.SelectCompany_wrapper__jh1zW:hover {
    background-color: var(--color-grey--050);
}

.SelectCompany_logo__Ln8Fx {
    width: 40px;
    height: 40px;
    overflow: hidden;
    border-radius: var(--radius-100);
    background-color: var(--color-grey--100);
}

.SelectCompany_nameWrapper__FNwQm {
    margin-bottom: var(--spacer-25);
}

.SelectCompany_name__0Lsjg {
    font-weight: bold;
}

.SelectCompany_tag__UdUgr {
    margin-left: var(--spacer-100);
}
}
@layer base {
  .SelectCompany_createCompanyAction___n1YW {
  margin-left: 50%;
  transform: translateX(-50%);
}

.SelectCompany_field__IW8Hd {
  margin-bottom: var(--spacer-25);
}

.SelectCompany_updateCompanyList__GiVt_ {
  margin-top: var(--spacer-100);
  font-size: var(--sk-font-size-1);
  font-weight: var(--bold);
}

.SelectCompany_tips__vs6o1 {
  margin-bottom: var(--spacer-300);
}

}
@layer base {
  .SelectContractTypes_field__zea_3 {
  margin-bottom: var(--spacer-400);
}

}
@layer base {
  .SelectPositionCategory_field__PY9Gl {
  margin-bottom: var(--spacer-400);
}

}
@layer base {
  .SelectTags_main__MHniJ {
  position: relative;
  /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
  z-index: 3;
}

.SelectTags_field__eEVAb {
  margin-bottom: var(--spacer-400);
}

}
@layer base {
  .MenuDropdown_dropdown__iM4YR {
  width: 100%;
}

.MenuDropdown_triggerButton__YJCKf {
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  padding: 0 var(--spacer-100);
  border-color: transparent;
  background-color: transparent;
  color: black;
  gap: var(--spacer-100);
  font-size: var(--normalBody);
  font-weight: var(--normal);
  text-align: left;
}

.MenuDropdown_triggerButton__selected__MR7xG {
  background-color: var(--color-black);
  color: var(--color-white);
}

.MenuDropdown_triggerButton__YJCKf:hover {
  background-color: var(--color-grey--100);
}

/* reset CSS from Dropdown's Button component */
.MenuDropdown_triggerButton__YJCKf:hover::before {
  background-color: transparent;
}

.MenuDropdown_triggerButton__YJCKf:focus {
  border-color: var(--color-black);
  background-color: transparent;
}

.MenuDropdown_triggerButton__selected__MR7xG:hover {
  background-color: var(--color-grey--800);
}

.MenuDropdown_triggerButton__selected__MR7xG:focus {
  background-color: var(--color-black);
}

.MenuDropdown_triggerButtonInitial__Nenpk {
  width: var(--spacer-300);
  height: var(--spacer-300);
  border-radius: var(--radius-900);
  background-color: var(--color-grey--100);
  color: var(--color-black) !important;
  font-size: 10px;
  text-align: center;
}

.MenuDropdown_triggerButton__YJCKf:not(.MenuDropdown_triggerButton__selected__MR7xG):hover .MenuDropdown_triggerButtonInitial__Nenpk {
  background-color: var(--color-grey--200);
}

.MenuDropdown_triggerButtonText__ICZyM {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.MenuDropdown_triggerButtonArrowWithTag__JsOD8 {
  display: flex;
  align-items: center;
  gap: var(--spacer-25);
}

.MenuDropdown_triggerButtonText___hidden__BxAoZ {
  display: none;
}

.MenuDropdown_dropdownUserMenuItemLanguage__9Sune {
  display: flex;
  justify-content: space-between;
  border-bottom: thin solid var(--color-grey--100);
}

.MenuDropdown_dropdownUserMenuItemCurrentLocale__ER_l2 {
  text-transform: uppercase;
}

.MenuDropdown_dropdownConsumptionMenuItem__5FgLt {
  display: flex;
  justify-content: space-between;
  gap: var(--spacer-200);
  pointer-events: none;
}

.MenuDropdown_dropdownConsumptionMenuItemLink__j_YkA {
  border-top: thin solid var(--color-grey--100);
}

.MenuDropdown_dropdownConsumptionMenuItemValue___FOWd {
  font-weight: var(--semiBold);
}

.MenuDropdown_newTag__4GObg {
  margin-left: var(--spacer-200);
}

}
